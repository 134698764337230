import React from 'react'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import ContentHero from '../components/ContentHero'
import InformationCard from '../components/InformationCard'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

const CareerPage = props => {
  const { data } = props
  return (
    <Layout>
      <Hero
        title="Mitgestalter"
        subtitle="Werte zu schaffen ist unsere Leidenschaft"
        img={data.hero.childImageSharp}
      />
      <div className="container">
        <ContentHero
          title="Arbeiten bei colenio"
          subtitle="Was uns von anderen unterscheidet, ist die Leidenschaft, mit der wir arbeiten und die tiefe technologische Expertise, mit der wir unsere Kunden überzeugen. Wir sind modern, gehen ständig neue Wege und bleiben nie stehen. Wir entwickeln uns stetig weiter. Unsere Dynamik spüren wir jeden Tag aufs neue. Jeder von uns trägt seinen Teil für die weitere Entwicklung von colenio bei. Die Vielfältigkeit der Aufgaben und Fragestellungen macht uns besonders. Bei uns hast du von Beginn an die Möglichkeit Kunden auf Geschäftsführerebene selbständig zu betreuen und so Einblicke in vielfältige Unternehmensstrukturen zu erhalten."
        />
        <section className="section">
          <div className="container">
            <div className="columns">
              <div className="column">
                <InformationCard
                  isSmall={true}
                  img={data.jobs.childImageSharp}
                  title="Stellenangebote"
                  link="/jobs"
                  content="Wir suchen immer wieder Menschen, die zu uns passen, unsere Werte und Ansprüche teilen. Du willst mitgestalten? Du bist ein echter Teamplayer und willst Dinge voran bringen? Dann schau dir unsere offenen Stellen an! Wir freuen uns über eine Bewerbung!"
                />
              </div>
              <div className="column">
                <InformationCard
                  isSmall={true}
                  title="Bewerbungsprozess"
                  img={data.application.childImageSharp}
                  link="/applicationprocess"
                  content="Hier stellen wir kurz vor, wie unser Bewerbungsprozess aussieht."
                />
              </div>
              <div className="column is-hidden">
                <InformationCard
                  isSmall={true}
                  title="FAQ"
                  img={data.faq.childImageSharp}
                  link="/faq"
                  content="Hier beantworten wir alle Fragen zum Thema Arbeiten bei colenio. Dinge die Sie schon immer wissen und nicht wissen wollten, finden Sie in unseren FAQ´s."
                />
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <InformationCard
                  isSmall={true}
                  title="Benefits"
                  img={data.benefits.childImageSharp}
                  link="/benefits"
                  content="Dass die colenio inhabergeführt und eigenverantwortlich ist, macht uns im Projektgeschäft unabhängig und selbstbestimmt. Das gilt auch für die Leistungen, die wir unseren Kollegen anbieten. Wir schauen genau hin und überlegen, welche Angebote wir wirklich brauchen, um unseren Arbeitsalltag möglichst angenehm zu gestalten."
                />
              </div>
              <div className="column">
                <InformationCard
                  isSmall={true}
                  title="Unser Team"
                  content="Unser Team besteht aus hochmotivierten, sehr gut ausgebildeten Experten, die ihr Fachgebiet verstehen und echte Teamplayer sind."
                  img={data.team.childImageSharp}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

CareerPage.propTypes = {
  data: PropTypes.shape({
    images: PropTypes.array,
  }),
}

export default CareerPage

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "team.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          aspectRatio: 3.1
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
    team: file(relativePath: { eq: "team-color-mod.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 800, width: 700)
      }
    }
    benefits: file(relativePath: { eq: "benefits-color-mod.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 800, width: 700)
      }
    }
    application: file(relativePath: { eq: "bewerbung-color-mod.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 800, width: 700)
      }
    }
    jobs: file(relativePath: { eq: "code-color-mod.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 800, width: 700)
      }
    }
    faq: file(relativePath: { eq: "faq-color-mod.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 800, width: 700)
      }
    }
  }
`
